<template>
  <v-container>
    <h1 class="mb-3 text-center">404 : Page Not Found</h1>
  </v-container>
</template>

<script>
  export default {
    title: '404'
  }
</script>
